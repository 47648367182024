import { HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { startCase } from 'lodash';
import { OsWorkflowTypes, PtWorkflowTypes, UnreadMessagesWorkflowTypes } from '../../../../../../custom-types';
import { State } from '../../../../../../test-utils/split';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../../acute-physical-therapy/constants/splits';
import { SPLIT_TREATMENTS } from '../../../../../member-360/components/communication/conversations/constants/splits';
import { CARE_TEAM, CARE_TEAM_MESSAGE, UNREAD_MESSAGE } from '../../../../../member-360/modules/panel-layouts/constants/panel';
import { COACH_WORKFLOW_TYPES } from '../../../../constants/strings/coach-workflow-types';
import { OS_WORKFLOW_TYPES } from '../../../../constants/strings/os-workflow-types';
import { PT_WORKFLOW_TYPES } from '../../../../constants/strings/pt-workflow-types';
import { useShellContext } from '../../../../contexts/shell.context';
import { WorkflowTypeCustomFieldsActionPlansFragment } from '../../../../types';
import { CoachWorkflowTypes } from '../../../../types/coach';
import { WorkflowTypeSecondaryText } from './workflow-type-secondary-text';
interface WorkflowTypeCellProps {
  workflow: WorkflowTypeCustomFieldsActionPlansFragment;
}
export const TitleCell = ({
  workflow
}: WorkflowTypeCellProps): JSX.Element => {
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const iamCareTeamMessagingTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.IAM_CARE_TEAM_MESSAGING, {
    adminUuid: adminProfile?.uuid || '*'
  }) === State.On;
  const modularIamUnreadMessageTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.MODULAR_IAM_UNREAD_MESSAGE, {
    adminUuid: adminProfile.uuid || '*'
  }) === State.On;
  const getTitle = (): JSX.Element => {
    const {
      type,
      customFields
    } = workflow;
    const isCareTeamMessage = iamCareTeamMessagingTreatment && customFields?.type === UnreadMessagesWorkflowTypes.CareTeamMessage;
    const isInAppMessage = customFields?.type === UnreadMessagesWorkflowTypes.InAppMessage && modularIamUnreadMessageTreatment;
    const renderTitle = (): string => {
      switch (true) {
        case type === PtWorkflowTypes.PtMemberAlertReminder:
          return startCase(customFields?.memberAlert?.category);
        case isCareTeamMessage:
          return modularIamUnreadMessageTreatment ? CARE_TEAM : CARE_TEAM_MESSAGE;
        case isInAppMessage:
          return UNREAD_MESSAGE;
        default:
          return COACH_WORKFLOW_TYPES[(type as CoachWorkflowTypes)] || PT_WORKFLOW_TYPES[(type as PtWorkflowTypes)] || OS_WORKFLOW_TYPES[(type as OsWorkflowTypes)];
      }
    };
    return <HHTypography hhVariant="body">{renderTitle()}</HHTypography>;
  };
  return <>
      {getTitle()}
      <WorkflowTypeSecondaryText workflow={workflow} />
    </>;
};