import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { ON, SPLIT_TREATMENTS } from '../../../../constants/splits';
import { PANEL_CATEGORY } from '../../../../constants/strings/coach-layout';
import { OS_PANEL_CATEGORY } from '../../../../constants/strings/os-layout';
import { PT_PANEL_CATEGORY } from '../../../../constants/strings/pt-layout';
import { useShellContext } from '../../../../contexts/shell.context';
import { DashboardType } from '../../../../custom-types';
import { CoachDashboardFilters } from '../../../../types/coach';
import { OsDashboardFilters } from '../../../../types/os';
import { PtDashboardFilters } from '../../../../types/pt';
interface FilterCategory<T> {
  name: Array<T>;
  category?: string;
  id: string;
  showDivider?: boolean;
  showCategoryHeader?: boolean;
}
export const useGetFilterCategories = dashboardType => {
  const $ = _c(89);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const unreadMessagesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGES_FILTER, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const healthLogNotesTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const encountersTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.ENCOUNTERS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const mediumTierRetentionTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.MEDIUM_TIER_RETENTION_WORKFLOWS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const reEngagerTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const careCoordinationWorkflowDashboardTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const outreachNeededTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.OUTREACH_NEEDED_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const firstWeekEnsoCheckInTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const ensoWeek1Treatment = splitClient?.getTreatment(SPLIT_TREATMENTS.ENSO_WEEK_1_WORKFLOWS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const weeklyEnsoReviewTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const setupActionPlanTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const isPtActionPlanReviewWorkflowSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const ptMemberAlertsRemindersTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_MEMBER_ALERTS_REMINDERS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const surgeryTrendTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.SURGERY_TREND_WORKFLOW_MIGRATION, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const isAqRedFlagsWorkflowSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.AQ_RED_FLAGS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachWeeklyGoalExpiry = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const coachMemberUpdatedWeeklyGoal = splitClient?.getTreatment(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const indicationUpdateSplitOn = splitClient?.getTreatment(SPLIT_TREATMENTS.INDICATION_UPDATE_WORKFLOW, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  let t0;
  bb0: {
    let tempAccordionList;
    if ($[0] !== dashboardType || $[1] !== encountersTreatment || $[2] !== unreadMessagesTreatment || $[3] !== healthLogNotesTreatment || $[4] !== reEngagerTreatment || $[5] !== careCoordinationWorkflowDashboardTreatment || $[6] !== mediumTierRetentionTreatment || $[7] !== coachWeeklyGoalExpiry || $[8] !== coachMemberUpdatedWeeklyGoal || $[9] !== ensoWeek1Treatment || $[10] !== weeklyEnsoReviewTreatment || $[11] !== outreachNeededTreatment || $[12] !== surgeryTrendTreatment || $[13] !== isAqRedFlagsWorkflowSplitOn || $[14] !== indicationUpdateSplitOn || $[15] !== setupActionPlanTreatment || $[16] !== isPtActionPlanReviewWorkflowSplitOn || $[17] !== firstWeekEnsoCheckInTreatment || $[18] !== ptMemberAlertsRemindersTreatment) {
      tempAccordionList = [];
      if (dashboardType === DashboardType.Coach) {
        if (encountersTreatment) {
          let t1;
          if ($[21] === Symbol.for("react.memo_cache_sentinel")) {
            t1 = {
              name: [CoachDashboardFilters.CoachCalls],
              id: "0",
              showDivider: true
            };
            $[21] = t1;
          } else {
            t1 = $[21];
          }
          tempAccordionList.push(t1);
        }
        let t1;
        if ($[22] === Symbol.for("react.memo_cache_sentinel")) {
          t1 = {
            name: [CoachDashboardFilters.All],
            category: "",
            showDivider: true,
            id: "1"
          };
          $[22] = t1;
        } else {
          t1 = $[22];
        }
        tempAccordionList.push(t1);
        let t2;
        let unreadWorkflows;
        if ($[23] !== unreadMessagesTreatment || $[24] !== healthLogNotesTreatment) {
          unreadWorkflows = [];
          if (unreadMessagesTreatment) {
            unreadWorkflows.push(CoachDashboardFilters.UnreadMessages);
          }
          if (healthLogNotesTreatment) {
            unreadWorkflows.push(CoachDashboardFilters.MemberHealthLog);
          }
          t2 = isEmpty(unreadWorkflows);
          $[23] = unreadMessagesTreatment;
          $[24] = healthLogNotesTreatment;
          $[25] = t2;
          $[26] = unreadWorkflows;
        } else {
          t2 = $[25];
          unreadWorkflows = $[26];
        }
        if (!t2) {
          let t3;
          if ($[27] !== unreadWorkflows) {
            t3 = {
              name: unreadWorkflows,
              id: "2",
              category: PANEL_CATEGORY.UNREADS,
              showCategoryHeader: true,
              showDivider: true
            };
            $[27] = unreadWorkflows;
            $[28] = t3;
          } else {
            t3 = $[28];
          }
          tempAccordionList.push(t3);
        }
        if (reEngagerTreatment) {
          let t3;
          if ($[29] === Symbol.for("react.memo_cache_sentinel")) {
            t3 = {
              name: [CoachDashboardFilters.ReEngager],
              id: "3"
            };
            $[29] = t3;
          } else {
            t3 = $[29];
          }
          tempAccordionList.push(t3);
        }
        if (careCoordinationWorkflowDashboardTreatment) {
          let t3;
          if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
            t3 = {
              name: [CoachDashboardFilters.CoachEscalation],
              id: "4"
            };
            $[30] = t3;
          } else {
            t3 = $[30];
          }
          tempAccordionList.push(t3);
        }
        let t3;
        if ($[31] === Symbol.for("react.memo_cache_sentinel")) {
          t3 = [CoachDashboardFilters.FirstWeekCheckIn, CoachDashboardFilters.FirstWeekReview];
          $[31] = t3;
        } else {
          t3 = $[31];
        }
        let t4;
        if ($[32] === Symbol.for("react.memo_cache_sentinel")) {
          t4 = {
            name: t3,
            category: PANEL_CATEGORY.FIRST_WEEK_GOAL,
            id: "5",
            showCategoryHeader: true
          };
          $[32] = t4;
        } else {
          t4 = $[32];
        }
        tempAccordionList.push(t4);
        let actionPlanWorkflows;
        if ($[33] !== mediumTierRetentionTreatment || $[34] !== coachWeeklyGoalExpiry || $[35] !== coachMemberUpdatedWeeklyGoal) {
          actionPlanWorkflows = [];
          if (mediumTierRetentionTreatment) {
            actionPlanWorkflows.push(CoachDashboardFilters.ActionPlanCheckIn, CoachDashboardFilters.ReviewAndRecalibrate);
          }
          if (coachWeeklyGoalExpiry) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachWeeklyGoalExpiry);
          }
          if (coachMemberUpdatedWeeklyGoal) {
            actionPlanWorkflows.push(CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal);
          }
          $[33] = mediumTierRetentionTreatment;
          $[34] = coachWeeklyGoalExpiry;
          $[35] = coachMemberUpdatedWeeklyGoal;
          $[36] = actionPlanWorkflows;
        } else {
          actionPlanWorkflows = $[36];
        }
        let t5;
        if ($[37] !== actionPlanWorkflows) {
          t5 = {
            name: actionPlanWorkflows,
            category: PANEL_CATEGORY.ACTION_PLAN,
            id: "6",
            showCategoryHeader: true
          };
          $[37] = actionPlanWorkflows;
          $[38] = t5;
        } else {
          t5 = $[38];
        }
        tempAccordionList.push(t5);
        let t6;
        let ensoWorkflows;
        if ($[39] !== ensoWeek1Treatment || $[40] !== weeklyEnsoReviewTreatment) {
          ensoWorkflows = [];
          if (ensoWeek1Treatment) {
            ensoWorkflows.push(CoachDashboardFilters.EnsoWeek1);
          }
          if (weeklyEnsoReviewTreatment) {
            ensoWorkflows.push(CoachDashboardFilters.WeeklyEnsoReview);
          }
          t6 = isEmpty(ensoWorkflows);
          $[39] = ensoWeek1Treatment;
          $[40] = weeklyEnsoReviewTreatment;
          $[41] = t6;
          $[42] = ensoWorkflows;
        } else {
          t6 = $[41];
          ensoWorkflows = $[42];
        }
        if (!t6) {
          let t7;
          if ($[43] !== ensoWorkflows) {
            t7 = {
              name: ensoWorkflows,
              category: PANEL_CATEGORY.ENSO,
              id: "7",
              showCategoryHeader: true
            };
            $[43] = ensoWorkflows;
            $[44] = t7;
          } else {
            t7 = $[44];
          }
          tempAccordionList.push(t7);
        }
        let t7;
        if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
          t7 = [CoachDashboardFilters.MaternityMilestones, CoachDashboardFilters.PregnancyStatusChange];
          $[45] = t7;
        } else {
          t7 = $[45];
        }
        let t8;
        if ($[46] === Symbol.for("react.memo_cache_sentinel")) {
          t8 = {
            name: t7,
            category: PANEL_CATEGORY.PELVIC_HEALTH,
            id: "8",
            showCategoryHeader: true
          };
          $[46] = t8;
        } else {
          t8 = $[46];
        }
        tempAccordionList.push(t8);
        let otherWorkflows;
        if ($[47] !== outreachNeededTreatment) {
          otherWorkflows = [CoachDashboardFilters.AlertReminder, CoachDashboardFilters.BalanceAssessment];
          if (outreachNeededTreatment) {
            otherWorkflows.push(CoachDashboardFilters.OutreachNeeded);
          }
          $[47] = outreachNeededTreatment;
          $[48] = otherWorkflows;
        } else {
          otherWorkflows = $[48];
        }
        let t9;
        if ($[49] !== otherWorkflows) {
          t9 = {
            name: otherWorkflows,
            category: PANEL_CATEGORY.OTHER_WORKFLOWS,
            id: "9",
            showDivider: true,
            showCategoryHeader: true
          };
          $[49] = otherWorkflows;
          $[50] = t9;
        } else {
          t9 = $[50];
        }
        tempAccordionList.push(t9);
        let t10;
        if ($[51] === Symbol.for("react.memo_cache_sentinel")) {
          t10 = {
            name: [CoachDashboardFilters.Completed],
            id: "10"
          };
          $[51] = t10;
        } else {
          t10 = $[51];
        }
        tempAccordionList.push(t10);
      } else {
        if (dashboardType === DashboardType.PhysicalTherapist) {
          let t1;
          if ($[52] === Symbol.for("react.memo_cache_sentinel")) {
            t1 = {
              name: [PtDashboardFilters.VideoVisit],
              id: "0"
            };
            $[52] = t1;
          } else {
            t1 = $[52];
          }
          let t2;
          if ($[53] === Symbol.for("react.memo_cache_sentinel")) {
            t2 = {
              name: [PtDashboardFilters.HouseCall],
              id: "1",
              showDivider: true
            };
            $[53] = t2;
          } else {
            t2 = $[53];
          }
          let t3;
          if ($[54] === Symbol.for("react.memo_cache_sentinel")) {
            t3 = {
              name: [PtDashboardFilters.All],
              id: "2",
              showDivider: true
            };
            $[54] = t3;
          } else {
            t3 = $[54];
          }
          let tempAccordionList_0;
          if ($[55] !== careCoordinationWorkflowDashboardTreatment || $[56] !== surgeryTrendTreatment || $[57] !== isAqRedFlagsWorkflowSplitOn || $[58] !== indicationUpdateSplitOn || $[59] !== setupActionPlanTreatment || $[60] !== isPtActionPlanReviewWorkflowSplitOn || $[61] !== firstWeekEnsoCheckInTreatment || $[62] !== ptMemberAlertsRemindersTreatment) {
            tempAccordionList_0 = [t1, t2, t3];
            let t4;
            if ($[64] === Symbol.for("react.memo_cache_sentinel")) {
              t4 = {
                name: [PtDashboardFilters.UnreadMessage],
                id: "3"
              };
              $[64] = t4;
            } else {
              t4 = $[64];
            }
            tempAccordionList_0.push(t4);
            let t5;
            let clinicalWorkflows;
            if ($[65] !== careCoordinationWorkflowDashboardTreatment || $[66] !== surgeryTrendTreatment || $[67] !== isAqRedFlagsWorkflowSplitOn || $[68] !== indicationUpdateSplitOn) {
              clinicalWorkflows = [];
              if (careCoordinationWorkflowDashboardTreatment) {
                clinicalWorkflows.push(PtDashboardFilters.MedicalEmergencyFollowUp);
                clinicalWorkflows.push(PtDashboardFilters.ClinicalEscalation);
                clinicalWorkflows.push(PtDashboardFilters.EtModification);
              }
              if (surgeryTrendTreatment) {
                clinicalWorkflows.push(PtDashboardFilters.SurgeryTrend);
              }
              if (isAqRedFlagsWorkflowSplitOn) {
                clinicalWorkflows.push(PtDashboardFilters.AqRedFlags);
              }
              if (indicationUpdateSplitOn) {
                clinicalWorkflows.push(PtDashboardFilters.IndicationUpdate);
              }
              t5 = isEmpty(clinicalWorkflows);
              $[65] = careCoordinationWorkflowDashboardTreatment;
              $[66] = surgeryTrendTreatment;
              $[67] = isAqRedFlagsWorkflowSplitOn;
              $[68] = indicationUpdateSplitOn;
              $[69] = t5;
              $[70] = clinicalWorkflows;
            } else {
              t5 = $[69];
              clinicalWorkflows = $[70];
            }
            if (!t5) {
              let t6;
              if ($[71] !== clinicalWorkflows) {
                t6 = {
                  name: clinicalWorkflows,
                  category: PT_PANEL_CATEGORY.CLINICAL_WORKFLOWS,
                  id: "4",
                  showCategoryHeader: true
                };
                $[71] = clinicalWorkflows;
                $[72] = t6;
              } else {
                t6 = $[72];
              }
              tempAccordionList_0.push(t6);
            }
            let t6;
            if ($[73] === Symbol.for("react.memo_cache_sentinel")) {
              t6 = [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.PainUptick];
              $[73] = t6;
            } else {
              t6 = $[73];
            }
            let t7;
            if ($[74] === Symbol.for("react.memo_cache_sentinel")) {
              t7 = {
                name: t6,
                id: "5",
                category: PT_PANEL_CATEGORY.HEALTH_LOGS,
                showCategoryHeader: true
              };
              $[74] = t7;
            } else {
              t7 = $[74];
            }
            tempAccordionList_0.push(t7);
            let t8;
            let actionPlanWorkflows_0;
            if ($[75] !== setupActionPlanTreatment || $[76] !== isPtActionPlanReviewWorkflowSplitOn) {
              actionPlanWorkflows_0 = [];
              if (setupActionPlanTreatment) {
                actionPlanWorkflows_0.push(PtDashboardFilters.SetupActionPlan);
              }
              if (isPtActionPlanReviewWorkflowSplitOn) {
                actionPlanWorkflows_0.push(PtDashboardFilters.ActionPlanReview);
              }
              t8 = isEmpty(actionPlanWorkflows_0);
              $[75] = setupActionPlanTreatment;
              $[76] = isPtActionPlanReviewWorkflowSplitOn;
              $[77] = t8;
              $[78] = actionPlanWorkflows_0;
            } else {
              t8 = $[77];
              actionPlanWorkflows_0 = $[78];
            }
            if (!t8) {
              let t9;
              if ($[79] !== actionPlanWorkflows_0) {
                t9 = {
                  name: actionPlanWorkflows_0,
                  category: PT_PANEL_CATEGORY.ACTION_PLANS,
                  id: "6",
                  showCategoryHeader: true
                };
                $[79] = actionPlanWorkflows_0;
                $[80] = t9;
              } else {
                t9 = $[80];
              }
              tempAccordionList_0.push(t9);
            }
            if (firstWeekEnsoCheckInTreatment) {
              let t9;
              if ($[81] === Symbol.for("react.memo_cache_sentinel")) {
                t9 = [PtDashboardFilters.FirstWeekEnsoCheckIn];
                $[81] = t9;
              } else {
                t9 = $[81];
              }
              let t10;
              if ($[82] === Symbol.for("react.memo_cache_sentinel")) {
                t10 = {
                  name: t9,
                  category: PT_PANEL_CATEGORY.ENSO,
                  id: "7",
                  showCategoryHeader: true
                };
                $[82] = t10;
              } else {
                t10 = $[82];
              }
              tempAccordionList_0.push(t10);
            }
            let t9;
            if ($[83] === Symbol.for("react.memo_cache_sentinel")) {
              t9 = [PtDashboardFilters.ExpiringCarePlans, PtDashboardFilters.DischargeAndClosePlan];
              $[83] = t9;
            } else {
              t9 = $[83];
            }
            let t10;
            if ($[84] === Symbol.for("react.memo_cache_sentinel")) {
              t10 = {
                name: t9,
                category: PT_PANEL_CATEGORY.CARE_PLANS,
                id: "8",
                showCategoryHeader: true
              };
              $[84] = t10;
            } else {
              t10 = $[84];
            }
            tempAccordionList_0.push(t10);
            if (ptMemberAlertsRemindersTreatment) {
              let t11;
              if ($[85] === Symbol.for("react.memo_cache_sentinel")) {
                t11 = [PtDashboardFilters.PtMemberAlertReminder];
                $[85] = t11;
              } else {
                t11 = $[85];
              }
              let t12;
              if ($[86] === Symbol.for("react.memo_cache_sentinel")) {
                t12 = {
                  name: t11,
                  category: PT_PANEL_CATEGORY.OTHERS,
                  id: "9",
                  showCategoryHeader: true,
                  showDivider: true
                };
                $[86] = t12;
              } else {
                t12 = $[86];
              }
              tempAccordionList_0.push(t12);
            }
            let t11;
            if ($[87] === Symbol.for("react.memo_cache_sentinel")) {
              t11 = {
                name: [PtDashboardFilters.Completed],
                id: "10"
              };
              $[87] = t11;
            } else {
              t11 = $[87];
            }
            tempAccordionList_0.push(t11);
            $[55] = careCoordinationWorkflowDashboardTreatment;
            $[56] = surgeryTrendTreatment;
            $[57] = isAqRedFlagsWorkflowSplitOn;
            $[58] = indicationUpdateSplitOn;
            $[59] = setupActionPlanTreatment;
            $[60] = isPtActionPlanReviewWorkflowSplitOn;
            $[61] = firstWeekEnsoCheckInTreatment;
            $[62] = ptMemberAlertsRemindersTreatment;
            $[63] = tempAccordionList_0;
          } else {
            tempAccordionList_0 = $[63];
          }
          t0 = tempAccordionList_0;
          break bb0;
        } else {
          if (dashboardType === DashboardType.OnboardingSpecialist) {
            let tempAccordionList_1;
            if ($[88] === Symbol.for("react.memo_cache_sentinel")) {
              tempAccordionList_1 = [{
                name: [OsDashboardFilters.All],
                id: "0",
                showDivider: true
              }];
              tempAccordionList_1.push({
                name: [OsDashboardFilters.UnreadMessage],
                id: "1",
                category: OS_PANEL_CATEGORY.UNREADS,
                showCategoryHeader: true,
                showDivider: true
              });
              tempAccordionList_1.push({
                name: [OsDashboardFilters.ReEngager],
                id: "2"
              });
              const otherWorkflows_0 = [OsDashboardFilters.AlertReminder, OsDashboardFilters.OutreachNeeded];
              tempAccordionList_1.push({
                name: otherWorkflows_0,
                category: PANEL_CATEGORY.OTHER_WORKFLOWS,
                id: "3",
                showDivider: true,
                showCategoryHeader: true
              });
              tempAccordionList_1.push({
                name: [OsDashboardFilters.Completed],
                id: "4"
              });
              $[88] = tempAccordionList_1;
            } else {
              tempAccordionList_1 = $[88];
            }
            t0 = tempAccordionList_1;
            break bb0;
          }
        }
      }
      $[0] = dashboardType;
      $[1] = encountersTreatment;
      $[2] = unreadMessagesTreatment;
      $[3] = healthLogNotesTreatment;
      $[4] = reEngagerTreatment;
      $[5] = careCoordinationWorkflowDashboardTreatment;
      $[6] = mediumTierRetentionTreatment;
      $[7] = coachWeeklyGoalExpiry;
      $[8] = coachMemberUpdatedWeeklyGoal;
      $[9] = ensoWeek1Treatment;
      $[10] = weeklyEnsoReviewTreatment;
      $[11] = outreachNeededTreatment;
      $[12] = surgeryTrendTreatment;
      $[13] = isAqRedFlagsWorkflowSplitOn;
      $[14] = indicationUpdateSplitOn;
      $[15] = setupActionPlanTreatment;
      $[16] = isPtActionPlanReviewWorkflowSplitOn;
      $[17] = firstWeekEnsoCheckInTreatment;
      $[18] = ptMemberAlertsRemindersTreatment;
      $[19] = tempAccordionList;
      $[20] = t0;
    } else {
      tempAccordionList = $[19];
      t0 = $[20];
    }
    t0 = tempAccordionList;
  }
  const accordionList = t0;
  return (accordionList as Array<FilterCategory<T>>);
};