export const OS_LAYOUT = {
  LOADER_ID: 'os-layout-loader',
  LAYOUT_CONTAINER_ID: 'os-layout',
  FILTER_CONTAINER_ID: 'os-dashboard-filter',
  WORKFLOWS_TABLE_CONTAINER_ID: 'os-workflows-table'
};
export const OS_PANEL_CATEGORY = {
  OTHERS: 'Others',
  UNREADS: 'Unreads'
};
export const DEFAULT_OS_DASHBOARD_TAB_NAME = 'All Incoming';
export const OS_NO_RESPONSE_PREDICTED_TAB_NAME = 'No Response Predicted';