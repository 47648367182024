import { SseEvents } from '../constants/strings/events';
import { CoachSseData, CoachWorkflowTypes, CommonCoachState } from '../custom-types';
import { CoachBulkTransition } from '../modules/dashboard/custom-types';
import { WorkflowPayload } from '../types';
export const getWorkflowSseData = (sseData: unknown): CoachSseData => {
  const taskSseData = (sseData as {
    type: string;
    dueDate: string;
    workflow: WorkflowPayload;
  });
  const workflowSseData = (sseData as CoachSseData);
  let workflow: CoachSseData;
  if (taskSseData.type === SseEvents.TaskUpdated) {
    const taskWorkflow = taskSseData.workflow;
    const taskDueDate = taskSseData.dueDate;
    workflow = {
      type: taskSseData.type,
      workflowId: taskWorkflow.id,
      serviceName: (taskWorkflow.type as CoachWorkflowTypes),
      stateName: (taskWorkflow.state as CommonCoachState),
      latestTaskDate: taskDueDate
    };
  } else {
    workflow = workflowSseData;
  }
  return workflow;
};
export const getBulkWorkflowSseData = (bulkSseData: unknown): CoachBulkTransition => {
  const bulkMarkData = (bulkSseData as CoachBulkTransition);
  return bulkMarkData;
};