import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';
import { coachQueueWorkflowCategories, ptQueueWorkflowCategories } from '../custom-types';
import { coachWorkflowIdMapsByQueueCategoryAtom, currentQueueCategoryIndexAtom, currentQueueWorkflowIdAtom, ptWorkflowIdMapsByQueueCategoryAtom, Sentinels, tempCurrentQueueCategoryIndexAtom, tempQueueWorkflowIdAtom } from '../state/queue-atoms';
import { OktaRole } from '../types/okta-roles';
export const useMethodToSetTempNextIdentifier = () => {
  const $ = _c(15);
  const [currentQueueCategoryIndex] = useAtom(currentQueueCategoryIndexAtom);
  const [currentQueueWorkflowId] = useAtom(currentQueueWorkflowIdAtom);
  const [, setTempCurrentQueueWorkflowId] = useAtom(tempQueueWorkflowIdAtom);
  const [, setTempCurrentQueueCategoryIndex] = useAtom(tempCurrentQueueCategoryIndexAtom);
  const coachWorkflowIdMaps = useAtomValue(coachWorkflowIdMapsByQueueCategoryAtom);
  const ptWorkflowIdMaps = useAtomValue(ptWorkflowIdMapsByQueueCategoryAtom);
  const [newTempId, setNewTempId] = useState(undefined);
  const [newTempCategory, setNewTempCategory] = useState(undefined);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  let t0;
  if ($[0] !== hingeHealthAuthState || $[1] !== coachWorkflowIdMaps || $[2] !== ptWorkflowIdMaps) {
    t0 = hingeHealthAuthState?.accessToken?.claims.roles.includes(OktaRole.Coach) ? coachWorkflowIdMaps : ptWorkflowIdMaps;
    $[0] = hingeHealthAuthState;
    $[1] = coachWorkflowIdMaps;
    $[2] = ptWorkflowIdMaps;
    $[3] = t0;
  } else {
    t0 = $[3];
  }
  const workflowIdMaps = t0;
  const workflowCategories = hingeHealthAuthState?.accessToken?.claims.roles.includes(OktaRole.Coach) ? coachQueueWorkflowCategories : ptQueueWorkflowCategories;
  let t1;
  if ($[4] !== workflowIdMaps || $[5] !== currentQueueCategoryIndex || $[6] !== currentQueueWorkflowId || $[7] !== workflowCategories.length || $[8] !== setTempCurrentQueueWorkflowId || $[9] !== setTempCurrentQueueCategoryIndex) {
    t1 = () => {
      const currentWorkflowIdentifier = workflowIdMaps[currentQueueCategoryIndex].get(currentQueueWorkflowId);
      const mapsOfWorkflowIds = workflowIdMaps;
      if (currentWorkflowIdentifier) {
        if (currentWorkflowIdentifier.nextId === Sentinels.End) {
          let newCategoryIndex = currentQueueCategoryIndex + 1;
          while (newCategoryIndex < workflowCategories.length && mapsOfWorkflowIds[newCategoryIndex].size === 0) {
            ++newCategoryIndex;
          }
          if (newCategoryIndex < workflowCategories.length) {
            const newWorkflowId = mapsOfWorkflowIds[newCategoryIndex].keys().next().value;
            setTempCurrentQueueWorkflowId(newWorkflowId);
            setTempCurrentQueueCategoryIndex(newCategoryIndex);
            setNewTempId(newWorkflowId);
            setNewTempCategory(newCategoryIndex);
          }
        } else {
          setTempCurrentQueueWorkflowId(currentWorkflowIdentifier.nextId);
          setTempCurrentQueueCategoryIndex(currentQueueCategoryIndex);
          setNewTempId(currentWorkflowIdentifier.nextId);
          setNewTempCategory(currentQueueCategoryIndex);
        }
      }
    };
    $[4] = workflowIdMaps;
    $[5] = currentQueueCategoryIndex;
    $[6] = currentQueueWorkflowId;
    $[7] = workflowCategories.length;
    $[8] = setTempCurrentQueueWorkflowId;
    $[9] = setTempCurrentQueueCategoryIndex;
    $[10] = t1;
  } else {
    t1 = $[10];
  }
  const setTemporaryNextIdentifier = t1;
  let t2;
  if ($[11] !== setTemporaryNextIdentifier || $[12] !== newTempId || $[13] !== newTempCategory) {
    t2 = {
      setTemporaryNextIdentifier,
      newTempId,
      newTempCategory
    };
    $[11] = setTemporaryNextIdentifier;
    $[12] = newTempId;
    $[13] = newTempCategory;
    $[14] = t2;
  } else {
    t2 = $[14];
  }
  return t2;
};