import { HHBox } from '@hinge-health/react-component-library';
import { ArrowForwardRounded } from '@mui/icons-material';
import { transformIndicationsToText } from '../../../../utils/transform-text';
export type Indication = {
  name: string;
  isPrimary: boolean;
};
export const IndicationUpdate = (previousIndications: Indication[], currentIndications: Indication[]): JSX.Element => <HHBox sx={{
  display: 'flex',
  alignItems: 'center'
}}>
    {transformIndicationsToText(previousIndications)}
    <ArrowForwardRounded fontSize="small" />
    {transformIndicationsToText(currentIndications)}
  </HHBox>;