/* eslint-disable @typescript-eslint/naming-convention */

import { OsDashboardFilters } from '../types/os';
import { DashboardFilterType } from './coach-dashboard';
import { DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER } from './strings/coach-dashboard-default';
import { DEFAULT_OS_DASHBOARD_TAB_NAME, OS_NO_RESPONSE_PREDICTED_TAB_NAME } from './strings/os-layout';
export type OsDashboardFilterType = DashboardFilterType<OsDashboardFilters>;
export const OsDashboardFilter: OsDashboardFilterType = {
  [OsDashboardFilters.All]: {
    filterLabel: OsDashboardFilters.All,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  },
  [OsDashboardFilters.UnreadMessage]: {
    filterLabel: OsDashboardFilters.UnreadMessage,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  },
  [OsDashboardFilters.UnreadMessageNoResponse]: {
    filterLabel: OsDashboardFilters.UnreadMessage,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: OS_NO_RESPONSE_PREDICTED_TAB_NAME
  },
  [OsDashboardFilters.AlertReminder]: {
    filterLabel: OsDashboardFilters.AlertReminder,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  },
  [OsDashboardFilters.ReEngager]: {
    filterLabel: OsDashboardFilters.ReEngager,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  },
  [OsDashboardFilters.OutreachNeeded]: {
    filterLabel: OsDashboardFilters.OutreachNeeded,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  },
  [OsDashboardFilters.Completed]: {
    filterLabel: OsDashboardFilters.Completed,
    filterPageNumber: DEFAULT_DASHBOARD_FILTER_PAGE_NUMBER,
    filterTabName: DEFAULT_OS_DASHBOARD_TAB_NAME
  }
};