import { acuteLayoutUrl, memberLayoutUrl, portalSidebarUrl } from '../../constants/strings/workflow-urls';
import { acuteSidebarWorkflows, CoachWorkflowTypes, PtWorkflowTypes, threePanelLayoutWorkflows } from '../../custom-types';
import { AllAcuteGroupTypes, VideoVisitsWithoutMedicalHistory, VideoVisitsWorkflows, VIDEO_VISIT_WORKFLOWS } from '../../modules/acute-physical-therapy/custom-types';
import { QueueWorkflowIdentifiers } from '../../state/queue-atoms';
export const buildWorkflowUrl = (workflow: QueueWorkflowIdentifiers | undefined, threePanelLayoutTreatmentON: boolean, videoVisitDeprecationTreatment?: boolean, groupType?: AllAcuteGroupTypes, panelLayoutWorkflows: string[] = threePanelLayoutWorkflows, acuteSidebarWorkflowList: string[] = acuteSidebarWorkflows): string | null => {
  if (!workflow) {
    return null;
  }
  if (acuteSidebarWorkflowList.includes((workflow?.type as PtWorkflowTypes)) && (!VIDEO_VISIT_WORKFLOWS.includes((workflow?.type as VideoVisitsWorkflows | VideoVisitsWithoutMedicalHistory)) || !videoVisitDeprecationTreatment)) {
    return acuteLayoutUrl(workflow.id, workflow.type);
  }
  return threePanelLayoutTreatmentON && panelLayoutWorkflows.includes((workflow?.type as CoachWorkflowTypes)) ? memberLayoutUrl(workflow.id, workflow.type, workflow.patientId) : portalSidebarUrl(workflow.id, workflow.type, groupType);
};
export const getPHIMaskingTooltip = (isPHIFieldsMasked: boolean): string => `PHI mask ${isPHIFieldsMasked ? 'enabled' : 'disabled'}`;